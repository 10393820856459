import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class TransformStringFilter {

    public FirstCharacterUppercase(Input: string) {
        if (!Input) return Input;
        return Input[0].toUpperCase() + Input.substr(1).toLowerCase();
    }
     titleCaseWord(word: string) {
        if (!word) return word;
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
      }

}