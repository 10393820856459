<div class="container-fluid">
<h1>Virgin Plus - Disclaimer</h1>
<ul class="terncondtioncontent">

    <li>
    Your promo consists of a monthly credit off the current price. The current price is subject to increase during your subscription. If the current price increases your monthly, price will increase but you will continue to receive your monthly credit during the promo. Pricing is based on continued subscription to 60GB 4G data plan at $35/mo. ($50/mo., less $15 credit for 24 months). Any change made to services may affect the price and/or result in the loss of credits or promotions, as the case may be, as eligibility conditions may vary.
    </li>
<li>
    Bring-your-own device rate plans are on a 30-day term and will continue month-to-month 
    thereafter. Rates are subject to change. See  <a href="https://www.virginplus.ca/en/support/legal-terms.html">virginplus.ca/terms</a>.
</li>
<li>
    A credit will be provided on the first bill for service fee incurred from activation prior to 
    service in use in Canada.
</li>
<li>
    Canada-Wide calling: Canada-Wide Calling applies to calls made from Canada to a 
Canadian number or calls received from any number while you are inside Canada.
</li>
<li>

    Plans with 4G LTE data speed may reach download speeds of up to 150 Mbps. 4G LTE 
plans provide the coverage and reliability of the LTE network. Speed and signal strength 
may vary with your device, configuration, Internet traffic, environmental conditions, 
applicable network management and other factors.
</li>
<li>
    Long distance charges on calls to the U.S. are 75¢/min. in addition to your local per 
minute rate.
</li>
<li>
    Unlimited Text Messaging is only valid when message is sent from Canada. Text 
    messages sent within the U.S. or internationally, premium text messages, text alerts, 
    messages sent with an instant messaging application and dial-up messages are not 
    included in any plan and cost extra.
</li>
<li>
    Standard out of country roaming and international long distance rates apply.
</li>
<li>
    Applicable 911 fees apply. Fees withheld will be remitted to the government.
</li>
<li>
    Apps on your smartphone use data, too. If you want to avoid Pay-Per-Use data charges 
make sure to turn data off on your phone.
</li>
<li>
    Data use over what is included in your plan is $20/GB up to 8GB, $0.02/MB thereafter. 
    (subject to change)
    
</li>
<li>
    Pay-per-use rates apply to usage exceeding what's included in your rate plan. Subject to 
change over time. See a list of full additional usage charges and one time charges.
</li>
<li>
    One-time connection service fee ($60) applies and will be waived with new activations.

</li>
<li>
    Prices above may increase during subscription. If required, Virgin will give you written 
notice of these price increases at least 30 days before the effective date. Taxes extra. 
Taxes, prorated charges and applicable usage charges are not set out above and will be 
shown on bill.
</li>
<li>
    Services are subject to Virgin Plus Terms of Service<a href="https://www.virginplus.ca/en/support/legal-terms.html"> virginplus.ca/terms</a>.
</li>
<li>
    For information on how to manage your Virgin Plus account, please visit 
    <a href="https://www.virginplus.ca/en/support/index.html?topic=my_account"> My Account 
Support</a>.
</li>
<li>
    For understanding your bill and usage, please visit <a href="https://www.virginplus.ca/en/support/index.html?topic=bills_services">Bill and Usage</a> 
</li>
<li>
    Please visit <a href="https://www.virginplus.ca/en/support/imei.ajax.html?topic=bills_services">here</a>  and ensure your phone is compatible before your departure to Canada.
</li>
</ul>
</div>

