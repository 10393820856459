<div class="container">
<div class="row">
<div class="col-md-12 shadow-lg px-md-5 py-3 border bg-white">
    <div class="text-center text-lg-center">
       <h1> Frequently Asked Questions</h1> 
    </div>
 <div class="test">
<ol start="1">
<li>
	What should I do if my departure date changes? <br>
    Contact us in advance, through your registered email ID to <a href="mailto:info@uniconnectsimplans.com"> info@uniconnectsimplans.com</a> 
    

</li>

<li>
    What should I do if I’ve already arrived in Canada and forgot to notify Uniconnect?<br>
    Contact us at <a href="mailto:info@uniconnectsimplans.com"> info@uniconnectsimplans.com</a>  and we will activate your SIM Card.
    

</li>

<li>
    When will my service be activated? <br>
    Your SIM Card is activated 24-48 hours prior to the arrival date provided by you. Your SIM Card will not work until your arrival in Canada. Although billing starts at the time of activation, you will receive a credit on your first bill for the days before the arrival date that you provided.
</li>

<li>

    Do I need to do anything to activate my service?<br>
    We need a copy of your visa and passport. If you have not provided the same earlier, email us on <a href="mailto:info@uniconnectsimplans.com"> info@uniconnectsimplans.com</a>
</li>
<li>
	How do I insert my SIM Card? <br>
    Visit  <a href="https://www.virginplus.ca/en/support/sim.html#faq
    ">  https://www.virginplus.ca/en/support/sim.html#faq
</a>
</li>
<li>
  What if I have questions about my mobility service? <br>
  Visit <a href="https://www.virginplus.ca/en/support/index.html"> https://www.virginplus.ca/en/support/index.html</a> 
</li>

<li>Can I sign up after I arrive in Canada <br> 
    The rate plans on Uniconnect website are exclusive for customers in India. They are not available in Canada.

</li>
<li>
    What is my Spending Limit? <br>

    Once your SIM is activated, your account will be automatically placed under a spending limit. Please ensure on time payment of your monthly bill to avoid service suspension. For more information, please visit <a href="https://www.virginplus.ca/en/support/faq.html?q=credit-limit">here.</a> 

</li>
<li>
    	Can I use my service internationally? <br>
    No, your service can only be used when you are in Canada. Your phone service will work once the SIM card is connected to the Virgin Network in Canada.
</li>

<li>
    	If you want to use the service outside of Canada in the future (e.g. when visiting family in India or travelling to another country), please contact Virgin Plus to remove roaming block prior to your departure from Canada.
</li>

<li>

    	How do I know if my phone is compatible with the mobility service? <br>
    Please visit here <a href="https://www.virginplus.ca/en/support/imei.ajax.html">https://www.virginplus.ca/en/support/imei.ajax.html </a>and ensure your phone is compatible before your departure to Canada.
</li>
</ol>

 </div>

</div>

<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
</div>

</div>
