import { Component } from '@angular/core';
import { ApplicationServiceService } from './Services/application-service.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public ApplicationService: ApplicationServiceService) {

  }

  title = 'CanadaSIMRegister';

}
