<header class="fixed-top border-bottom bg-canada text-white text-center">
  <div class="container">
    <nav class="navbar navbar-expand-md navbar-dark py-0">
      <div class="container-fluid"> 
        <div style="margin-top: -5px; margin-left:-15px;">
          <a class="navbar-brand canada-logo" href="/">
            <img src="../../assets/AppImages/VirginPlus_White only_RGB_New.png" alt="Uniconnect"  height="80px">
          </a>
        </div>
        <div style="margin-top: 15px; margin-left:-15px;">
          <a class="navbar-brand" href="/">
            <img src="../../assets/AppImages/Uniconnect Logo AA.png" alt="Uniconnect" height="62px" width="100px">
          </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div style="margin-left: -15px;" class="collapse navbar-collapse border-top border-top-md-0 f-italic" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link fw-6 active" aria-current="page" href="#"> <img
                  src="../../assets/AppImages/cell-phone.svg" alt="Uniconnect" height="27" width="27"> 9:30 AM to 6:00
                PM Support</a>
            </li>
          </ul>
          <div class="rightnav col-md-5 col-lg-5 text-md-end">
            <div class="contactlinks d-inline-block text-md-start">
              <a href="tel:+918097735336" class="nav-item">
                <i class="bi bi-whatsapp"></i>
                <img src="../../assets/AppImages/phone-call.svg" alt="Uniconnect" height="27" width="27">
                +91 897 6012540
              </a>
              <a class="nav-item"
                href="https://api.whatsapp.com/send?phone=+918976012540&text=Lets%20talk%20to%20uniconnectvirginplus!"
                target="_blank">
                <i class="bi bi-whatsapp"></i>
                <img src="../../assets/AppImages/whatsapp.svg" alt="Uniconnect" height="27" width="27">
                +91 897 6012540
              
              </a>
              <a class="nav-item" href="mailto:info@uniconnectsimplans.com"><img height="27" width="27"
                  src="../../assets/AppImages/email.svg">info@uniconnectsimplans.com</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>