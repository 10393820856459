<div class="container">
    <div>
        &nbsp;
    </div>
    <div>
        &nbsp;
    </div>
    <div>
        &nbsp;
    </div>
    <div class="text-center">
        <p>
            Hi <b> {{SIMRegistrationResponseModel.firstName}} </b>, Your SIM Card has been Registered
            <b> {{SIMRegistrationResponseModel.simNumber}} </b><br><br>A notification email will follow of the
            Phone number on your SIM card 72 hours prior to your travel date
            <!-- <a href="/SIMCardRegistration">
                <h4>Click here Register other SIM Card</h4>
            </a> -->
        </p>
        <p>
            You will soon be receiving a call, confirming all information required for your SIM activation.
        </p>

        <a class="button" href="https://www.uniconnectsimplans.com/">Click here to register another SIM Card</a>
    </div>

    <br>
    <br>
    <br>
    <br>

</div>