<footer class="bd-footer pt-5 mt-5 bg-light border-top">
    <div class="container pt-5">
      <!-- <div class="row">
        <div class="col-lg-3 mb-3">
          <ul class="list-unstyled small text-muted">
            <li class="mb-2">Designed and built with all the love in the world by the <a href="#">Bootstrap team</a> with the help of <a href="#">our contributors</a>.</li>
            <li class="mb-2">Code licensed <a href="#" target="_blank" rel="license noopener">MIT</a>, docs <a href="#" target="_blank" rel="license noopener">CC BY 3.0</a>.</li>
            <li class="mb-2">Currently v5.0.2.</li>
          </ul>
        </div>
        <div class="col-6 col-lg-2 offset-lg-1 mb-3">
          <h5>Links</h5>
          <ul class="list-unstyled">
            <li class="mb-2"><a href="/">Home</a></li>
            <li class="mb-2"><a href="#">Docs</a></li>
            <li class="mb-2"><a href="#">Examples</a></li>
            <li class="mb-2"><a href="#">Themes</a></li>
            <li class="mb-2"><a href="#">Blog</a></li>
          </ul>
        </div>
        <div class="col-6 col-lg-2 mb-3">
          <h5>Guides</h5>
          <ul class="list-unstyled">
            <li class="mb-2"><a href="#">Getting started</a></li>
            <li class="mb-2"><a href="#">Starter template</a></li>
            <li class="mb-2"><a href="#">Webpack</a></li>
            <li class="mb-2"><a href="#">Parcel</a></li>
          </ul>
        </div>
        <div class="col-6 col-lg-2 mb-3">
          <h5>Projects</h5>
          <ul class="list-unstyled">
            <li class="mb-2"><a href="#">Bootstrap 5</a></li>
            <li class="mb-2"><a href="#">Bootstrap 4</a></li>
            <li class="mb-2"><a href="#">Icons</a></li>
            <li class="mb-2"><a href="#">RFS</a></li>
            <li class="mb-2"><a href="#">npm starter</a></li>
          </ul>
        </div>
        <div class="col-6 col-lg-2 mb-3">
          <h5>Community</h5>
          <ul class="list-unstyled">
            <li class="mb-2"><a href="#">Issues</a></li>
            <li class="mb-2"><a href="#">Discussions</a></li>
            <li class="mb-2"><a href="#">Corporate sponsors</a></li>
            <li class="mb-2"><a href="#">Open Collective</a></li>
            <li class="mb-2"><a href="#">Slack</a></li>
            <li class="mb-2"><a href="#">Stack Overflow</a></li>
          </ul>
        </div>
      </div> -->
    </div>
  </footer>
