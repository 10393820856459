export class SIMRegistration {
  id: number;
  registrationID: string;
  firstName: string;
  lastName: string;
  contact: string;
  sourceName: string = 'virginplus';
  cityReceived: string = '';
  emailID: string;
  isConsent: boolean = false;
  termandcondition: boolean = false;
}

export class UserResponse {
  userID?: number;
  appUserName: string;
  appPassword: string;
  UserRolesModel: Array<UserRolesModel>;
}

export class UserRolesModel {
  AppUserRoleName: string;
  AppUserRoleID?: number;
}

export class FileUploadModel {
  fileToUpload: File | null = null;
  id: string;
  SIMRegistrationID: string;
  uploadtype: string;
  userID: string;
}
