<div class="container">
    <form #sf="ngForm" id="sf" novalidate>
        <div class="row">
            <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-3 border bg-white">

                <h4 class="secondheader mb-4">Enter SIM number to get Plan detail<sup class="text-danger"> *</sup> </h4>
                <!-- <p class="secondheader2">(Starts with 89 and contains 20 digits) </p> -->

                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control form-control-sm" name="SImNumber" id="SImNumber" placeholder="SIM No"
                                [(ngModel)]="SIMRegistrationSecModel.simNumber" required #SImNumber="ngModel" ngModel
                                (ngModelChange)="ValidatetheSIMCard($event)"
                                [ngClass]="{'input-error': (!SImNumber?.valid && (SImNumber?.dirty || SImNumber?.touched))}"
                                
                                >
                            <button class="btn btn-danger" type="button" id="button-addon2"
                                (click)="ValidatetheSIMCard(SIMRegistrationSecModel.simNumber)">Go</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="img-fluid" src="../../assets/AppImages/sim-large.png">
                    </div>
                    <div *ngIf="!SImNumber?.valid && (SImNumber?.dirty || SImNumber?.touched)">
                        SIM number required
                    </div>
                    <div class="alert alert-danger" *ngIf="SIMCardStatus">
                        <!-- We have already received following sim card {{SIMRegistrationResponseModel.SIMCardNumberICCID}}
                        registration
                        request for the against of {{SIMRegistrationResponseModel.Indianmobilenumber}}. Please try with
                        another mobile number. -->
                        Please provide the valid sim card.!
                        <br>
                        <button type="button" class="btn btn-danger" (click)="NewSaleEntry()">
                            Back to home</button>
                    </div>
                </div>
                <div id="DisplayPlanDetails" *ngIf="DisplayPlanMessage">
                    <div class="row">
                        <h4 class="secondheader2 sc-color my-3 text-start">Virgin Plus Plan Details:</h4>
                    </div>
                    <p>
                        Monthly Post paid plan of $35 + taxes, no payment or credit card details is required as of now.
                    </p>
                    <ul class="PlanDetail">
                        <li>60GB 4G LTE speeds data</li>
                        <li>Unlimited Canada-wide calling</li>
                        <li>Unlimited worldwide text messaging from Canada</li>
                        <li>Call display and voicemail</li>
                        <li>Bonus Add-on: 1000 long distance calling minutes from Canada per month<br>
                            (Included countries: India, Bangladesh, China, Hong Kong, Mexico, UK )
                            </li>
                            <li>
                             Member benefit
                            </li>
                    </ul>
                   
                </div>

                <div id="DisplayPlanDetailsKoodoo" *ngIf="DisplayPlanMessageKoodoo">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        This is a Prepaid plan of $45 from Koodoo (Telus). No payment is required and the plan is
                        complimentary for
                        first month. The SIM will be activated the moment you land in Canada and insert in mobile. For
                        the mobile
                        number please call another mobile and make note of it. The automated SMS takes a few hours to be
                        received. The plan includes:
                    </p>
                    <ul>
                        <li>5GB internet at 4G speed</li>
                        <li>Unlimited Canada wide calling</li>
                        <li>100 minutes to India</li>
                    </ul>
                   
                    <div>
                        <h3>
                            <b> The plan is complimentary for first month and the same can be renewed on the Koodoo
                                website or through
                                any retailer</b>
                        </h3>
                    </div>
                </div>
              
                <div id="mandatoryfield" *ngIf="DisplayMandatoryDiv">

                    <div>
                        <p class="readDISCLAIMER sc-color my-3 text-start"><u> <span (click)="openTermCondition()">  READ THE VIRGIN PLUS - DISCLAIMER</span> </u></p>
    
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="FirstName" class="col-form-label textbold">First Name<sup class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                          <input type="text" class="form-control form-control-sm first-letter" id="FirstName" aria-label="First name" required
                            [(ngModel)]="SIMRegistrationSecModel.firstName" #FirstName="ngModel" name="FirstName"
                            [ngClass]="{'input-error': (!FirstName?.valid && (FirstName?.dirty || FirstName?.touched))}">
                          <div *ngIf="!FirstName?.valid && (FirstName?.dirty || FirstName?.touched)">
                            First Name
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="LastName" class="col-form-label textbold">Last Name<sup class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                          <input type="text" class="form-control form-control-sm first-letter" id="LastName" aria-label="Last name"
                            [(ngModel)]="SIMRegistrationSecModel.lastName" required name="LastName" #LastName="ngModel"
                            [ngClass]="{'input-error': (!LastName?.valid && (LastName?.dirty || LastName?.touched))}"
                            >
                          <div *ngIf="!LastName?.valid && (LastName?.dirty || LastName?.touched)">
                            Last Name
                          </div>
                        </div>
                      </div>

                      <!-- <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="email" class="col-form-label textbold">Email ID<sup class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                          <input type="email" class="form-control form-control-sm first-letter" id="EmailID" aria-label="email"
                            [(ngModel)]="SIMRegistrationSecModel.emailID" required name="EmailID" #EmailID="ngModel"
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" [email]="true"
                            [ngClass]="{'input-error': (!EmailID?.valid && (EmailID?.dirty || EmailID?.touched))}"
                            >
                          <div *ngIf="!EmailID?.valid && (EmailID?.dirty || EmailID?.touched)">
                            Email ID
                          </div>
            
                        </div>
                      </div> -->

                           <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Email ID<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                            <input type="email" class="form-control form-control-sm" id="emailID" aria-label="email"
                                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                                [(ngModel)]="SIMRegistrationSecModel.emailID" required name="emailID" #emailID="ngModel"
                                [ngClass]="{'input-error': (!emailID?.valid && (emailID?.dirty || emailID?.touched))}">
                            <div *ngIf="!emailID?.valid && (emailID?.dirty || emailID?.touched)">
                                Email ID
                            </div>
                        </div>
                    </div> 
                      <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                          <label for="mobile" class="col-form-label textbold">Indian Mobile Number<sup
                              class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-4">
                              <input type="text" value="+91" class="form-control form-control-sm first-letter" disabled>
                            </div>
                            <div class="col-md-8">
                              <input type="text" class="form-control form-control-sm first-letter" id="Indianmobilenumber" aria-label="mobile"
                                [(ngModel)]="SIMRegistrationSecModel.contact" required name="Indianmobilenumber"
                                #Indianmobilenumber="ngModel" (keypress)="ValidateMobilenumber($event)" minlength=10 maxlength=10
                                [ngClass]="{'input-error': (!Indianmobilenumber?.valid && (Indianmobilenumber?.dirty || Indianmobilenumber?.touched))}"
                                
                                >
                              <div *ngIf="!Indianmobilenumber?.valid && (Indianmobilenumber?.dirty || Indianmobilenumber?.touched)">
                                Mobile Number
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
              
                      <div class="row mb-md-4 align-items-center">
                        <div class="col-md-4">
                          <label for="CityReceived" class="col-form-label textbold">City where SIM received<sup
                              class="text-danger">*</sup></label>
                             
                        </div>
                        <div class="col-md-6">
              
                          <select 
                          [ngClass]="{'input-error': (!CityReceived?.valid && (CityReceived?.dirty || CityReceived?.touched))}"
                          class="form-select form-control-sm" aria-label="Default select example"
                            [(ngModel)]="SIMRegistrationSecModel.cityReceived" name="CityReceived" id="CityReceived"
                            #CityReceived="ngModel" (change)='onOptionsSelected($event)' required> 
                                                        <option *ngFor="let Item of City" [ngValue]="Item.name">
                            <!-- --[selected]="Item.Name === 'Ahmedabad'"> -->
                              {{Item.name}}
                            </option>
                          </select>
              
                          <div *ngIf="!CityReceived?.valid && (CityReceived?.dirty || CityReceived?.touched)">
                            Required
                          </div>
              
                          <div *ngIf="CityReceivedErrorMessage">
                            Required
                          </div>
              
                        </div>
                      </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="date" class="col-form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div clas="col-md-6">

                                    <div class="input-group">
                                        <input placeholder="Date of Arrival" matInput [matDatepicker]="pickerdot"
                                            id="DateofTravel" name="DateofTravel" class="form-control form-control-sm"
                                            [(ngModel)]="SIMRegistrationSecModel.arrivalDate" #arrivalDate="ngModel"
                                             required [min]="Startdate"  
                                             [ngClass]="{'input-error': (!arrivalDate?.valid && (arrivalDate?.dirty || arrivalDate?.touched))}">
                                        <mat-datepicker-toggle matSuffix [for]="pickerdot"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerdot></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!arrivalDate?.valid && (arrivalDate?.dirty || arrivalDate?.touched)">
                                Arrival Date
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="tdate" class="col-form-label textbold">Arrival Status<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                            <select class="form-select form-control-sm" aria-label="Arrival Status"
                                [(ngModel)]="SIMRegistrationSecModel.arrivalStatus" name="arrivalStatus"
                                id="arrivalStatus" #arrivalStatus="ngModel"  required
                                [ngClass]="{'input-error': (!arrivalStatus?.valid && (arrivalStatus?.dirty || arrivalStatus?.touched))}">
                                
                                <option selected>Confirmed</option>
                                <option>Tentative</option>
                            </select>
                            <div
                                *ngIf="!arrivalStatus?.valid && (arrivalStatus?.dirty || arrivalStatus?.touched)">
                                Arrival Status
                            </div>
                        </div>
                    </div>

                
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="Canadianaddress" class="col-form-label textbold">Canadian address<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                            <textarea  class="form-control form-control-sm" id="Canadianaddress" aria-label="Canadianaddress"
                              
                                [(ngModel)]="SIMRegistrationSecModel.canadianAddress"  name="canadianAddress" #canadianAddress="ngModel"
                                [ngClass]="{'input-error': (!canadianAddress?.valid && (canadianAddress?.dirty || canadianAddress?.touched))}"  required></textarea>
                             <div *ngIf="!canadianAddress?.valid && (canadianAddress?.dirty || canadianAddress?.touched)">
                                Canada address
                            </div> 
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="Province" class="col-form-label textbold">Province<sup
                                    class="text-danger"></sup></label>
                        </div>
                        <div class="col-md-6">
                        
                         <select class="form-select form-control-sm" aria-label="Default select example" name="province"
                                id="province"  [(ngModel)]="SIMRegistrationSecModel.province"
                                #province="ngModel" required 
                                [ngClass]="{'input-error': (!province?.valid && (province?.dirty || province?.touched))}"
                                >
                                <option *ngFor="let Province of ProvinceList" [ngValue]="Province"
                                    [selected]="Province === 'Alberta'">
                                    {{Province}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="PostalCode" class="col-form-label textbold">Postal Code<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="form-control form-control-sm" id="postalCode" aria-label="postalCode"
                                
                                [(ngModel)]="SIMRegistrationSecModel.postalCode"  name="postalCode" #postalCode="ngModel"
                                [ngClass]="{'input-error': (!postalCode?.valid && (postalCode?.dirty || postalCode?.touched))}">
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="UniversityName" class="col-form-label textbold">University Name<sup
                                    class="text-danger"></sup></label>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="form-control form-control-sm" id="universityName" aria-label="UniversityName"
                               
                                [(ngModel)]="SIMRegistrationSecModel.universityName"  name="universityName" #universityName="ngModel"
                                >
                           
                        </div>
                    </div>
                   
                    <div class="row mb-4 align-items-center">

                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Passport front file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                            <input class="form-control form-control-sm" placeholder="Front" type="file" id="passportfilename"
                            name="passportfilename"  
                            [(ngModel)]="SIMRegistrationSecModel.passportfilename"
                            #passportfilename="ngModel"  required accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'PassportFront', SIMRegistrationSecModel, sf)"
                            [ngClass]="{'input-error': (!passportfilename?.valid && (passportfilename?.dirty || passportfilename?.touched))}"
                            
                            >
                           
                              <div class="alert-danger" *ngIf="ActionMessageFront === 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageFront}}
                              </div>

                              <div *ngIf="ActionMessageFront !== 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageFront}}
                              </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Passport back file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                            <input class="form-control form-control-sm" placeholder="Front" type="file" id="passportbackfilename"
                            name="passportbackfilename" 
                            [(ngModel)]="SIMRegistrationSecModel.passportbackfilename"
                            #passportbackfilename="ngModel"
                            required accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'PassportBack', SIMRegistrationSecModel, sf)"
                            
                            [ngClass]="{'input-error': (!passportbackfilename?.valid && (passportbackfilename?.dirty || passportbackfilename?.touched))}"
                            >

                            <div class="alert-danger" *ngIf="ActionMessageBack === 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageBack}}
                              </div>

                              <div *ngIf="ActionMessageBack !== 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageBack}}
                              </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">

                        <div class="col-md-4">
                            <label for="email" class="col-form-label textbold">Visa stamp copy file<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-6">
                            <input class="form-control form-control-sm" required placeholder="Front" type="file" id="visafilename"
                            name="visafilename" 
                            [(ngModel)]="SIMRegistrationSecModel.visafilename"
                             #visafilename="ngModel"
                            accept="image/png, image/gif, image/jpeg, .pdf, .PDF"
                            (change)="handleFileInputPassportFront($event.target.files, 'Visa', SIMRegistrationSecModel, sf)" 
                            [ngClass]="{'input-error': (!visafilename?.valid && (visafilename?.dirty || visafilename?.touched))}"
                            
                            >
                            <div class="alert-danger" *ngIf="ActionMessageVisa === 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageVisa}}
                              </div>
                              <div *ngIf="ActionMessageVisa !== 'Maximum file size is allowed to upload only 1.5 MB'">
                                {{ActionMessageVisa}}
                              </div>
                        </div>
                    </div>

                    <div class="row mb-4 align-items-center consent1">
                        <div class="col-12">

                            <label for="isTermandCondition" class="col-form-label">
                            <input class="largerCheckbox" type="checkbox" id="isTermandCondition" name="isTermandCondition" 
                            [(ngModel)]="SIMRegistrationSecModel.isTermandCondition" #isTermandCondition="ngModel" [required]="true"
                            [ngClass]="{
                'is-invalid': isTermandCondition?.invalid,
                'valid': !isTermandCondition?.valid && (isTermandCondition?.dirty || isTermandCondition?.touched)
           }" required>

                        
                         I consent to the <span class="readDISCLAIMER sc-color my-3 text-start" (click)="openUniconnectTermCondition()">TERMS & CONDITIONS</span> of Uniconnect SIM Pvt. Ltd. as provided on its website and authorize Uniconnect SIM Pvt. Ltd. to provide my personal information to Virgin Plus/Bell Mobility for the purpose of activation of my SIM Card and providing telecom services.
                           
                           </label>

                            <div *ngIf="!isTermandCondition?.valid && (isTermandCondition?.dirty || isTermandCondition?.touched)">
                             <label class="mat-error">Please read the term and conditions and accept that !
                             </label> 
                           </div> 
                           <br>
                                       
                        </div>
                                       
                    </div>
                    <div class="row mb-4 align-items-center consent1">
                      
                        <div class="col-12">
                            I have read and agree to Virgin Plus Terms & Conditions.
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">

                        <div class="col-1 col-md-1 col-sm-1">
                            <input type="checkbox" id="agrrementwithYou" name="agrrementwithYou" required
                            [(ngModel)]="SIMRegistrationSecModel.agrrementwithYou" #agrrementwithYou="ngModel"
                            >

                            <div *ngIf="!agrrementwithYou?.valid && (agrrementwithYou?.dirty || agrrementwithYou?.touched)">
                                <label class="mat-error"> Read and agree to Virgin Plus Terms & Conditions!
                                </label> 
                              </div> 

                        </div>
                        <div class="col-11 col-md-11 col-sm-11">
                            <div class="col-lg-12 col-md-12 col-xs-12">  
                                <!-- <iframe  scrolling="auto" frameborder="0"        
                                  style="position: relative; width:100%; height: 400px;" allowfullscreen src="../../assets/Document/TERMSOFSERVICE_VIRGIN_WCOC_EN_ROC_20221106.pdf#toolbar=0" marginheight="0" marginwidth="0">
                                </iframe> -->
                                <!-- style="position:relative; width:100%; height: 400px;" -->
                                <!-- style="padding-bottom:56.25%; position:relative; display:block; width: 100%" -->

                                <!-- https://www.uniconnectvirginplus.com/assets/Document/TERMSOFSERVICE_VIRGIN_WCOC_EN_ROC_20221106.pdf -->
                                <!-- ../../assets//Document//TERMSOFSERVICE_VIRGIN_WCOC_EN_ROC_20221106.pdf#toolbar=0&page=1&zoom=100 -->
                            <!-- <div style="position:relative; display:block; width: 100%">
                                <iframe width="100%" height="300px"
                                  src="https://www.uniconnectvirginplus.com/assets/Document/TERMSOFSERVICE_VIRGIN_WCOC_EN_ROC_20221106.pdf#toolbar=0&zoom=100"
                                  scrolling="auto" frameborder="0"  allowfullscreen="true" loading="eager"  title="Uniconnect virgin plus term of service">
                                </iframe>
                              </div> -->

                              <div style="position:relative; display:block; width: 100%">
                                <iframe width="100%" height="300px"
                                  src="https://www.uniconnectsimplans.com/assets/Document/TERMSOFSERVICE_VIRGIN_WCOC_EN_ROC.html#toolbar=0&zoom=100"
                                  scrolling="auto" frameborder="0"  allowfullscreen="true" loading="eager"  title="Uniconnect virgin plus term of service">
                                </iframe>
                              </div>

                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-1 col-md-1 col-sm-1">
                            <input type="checkbox" id="verginplusconsent" name="verginplusconsent" 
                            [(ngModel)]="SIMRegistrationSecModel.verginplusconsent" #verginplusconsent="ngModel" required
                            >
                            <div *ngIf="!verginplusconsent?.valid && (verginplusconsent?.dirty || verginplusconsent?.touched)">
                                <label class="mat-error"> Please provide the consent detail
                                </label> 
                              </div> 
                        </div>
<div class="col-11 col-md-11 col-sm-11 consent1">
    I consent to Virgin Plus’s collection of my credit and payment information from other Bell companies, vendors, credit grantors and reporting agencies over time. I also consent to the use and disclosure of this information to these parties to assist with assessing my creditworthiness, and Virgin Plus’s disclosure of this information to these parties to assist with the collection of monies owed, all from time to time. I understand that when I sign up for a monthly plan, Virgin Plus is required to perform a credit check, and will use the information I provided above to do so.
</div>
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-1 col-md-1 col-sm-1">

                        </div>
                        <div class="col-11 col-md-11 col-sm-11 consentpartner">
                            Uniconnect is an official telecommunications partner in representation of Virgin Plus.
                        </div>
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-12 text-center">
                             <!-- {{sf.value | json }} 
                             {{sf.invalid}} -->
                            <button type="button" class="btn btn-danger" (click)="OnBackButton()"> &lt;
                                Previous</button>
                            &nbsp; &nbsp;
                            <button type="button" class="btn btn-danger" (click)="OnSubmit(SIMRegistrationSecModel)"
                                [disabled]="sf.invalid">Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="container-fluid">
        <ng-template #termandcondition let-modal data-keyboard="false" data-backdrop="static">
          <div class="modal-header" data-keyboard="false" data-backdrop="static">
            <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <app-termand-condition></app-termand-condition>
          </div>
        </ng-template>
      </div>

      <div class="container-fluid">
        <ng-template #uniconnecttermandcondition let-modal data-keyboard="false" data-backdrop="static">
          <div class="modal-header" data-keyboard="false" data-backdrop="static">
            <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <app-uniconnecttermandcondition></app-uniconnecttermandcondition>
          </div>
        </ng-template>
      </div>

</div>