import {
  Component,
  OnInit,
  NgModule,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { FormsModule, Form, NgForm } from '@angular/forms/';
import { ReactiveFormsModule, Validators } from '@angular/forms/';
import { CommonModule } from '@angular/common';
import {
  SIMRegistration,
  UserResponse,
  UserRolesModel,
  FileUploadModel,
} from '../Models/simregistration';
import {
  SIMRegistrationSec,
  SIMRegistrationAddress,
} from '../Models/simregistration-sec';
import { ApplicationServiceService } from '../Services/application-service.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  SIMRegistrationResponse,
  UserFiles,
} from '../Models/SIMRegistrationResponse';
import { SIMValidateResponse } from '../Models/SIMValidateResponse';
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { City } from '../Models/City';
import { FileUploadServiceService } from '../FileService/file-upload-service.service';

@Component({
  selector: 'app-sim-card-registration-cont',
  templateUrl: './sim-card-registration-cont.component.html',
  styleUrls: ['./sim-card-registration-cont.component.scss'],
})
export class SImCardRegistrationContComponent implements OnInit {
  public RegistrationID: string;
  public SIMRegistrationSecModel: SIMRegistrationAddress;
  public SIMRegistration: SIMRegistration;
  public SIMCardStatus = false;
  public SIMRegistrationResponseModel: SIMRegistrationResponse;
  public SImNo: string;
  public IsValidSIMNumber = true;
  public DisplayPlanMessage = false;
  public Startdate: Date;
  public DisplayMandatoryDiv = false;
  public SIMValidateResponse: SIMValidateResponse;
  public DisplayPlanMessageKoodoo = false;
  public IsSubmitted = false;
  public NetworkName = 'VirginPlus';
  FileUploadModel: FileUploadModel;
  ActionMessageFront: string;
  ActionMessageBack: string;
  ActionMessageVisa: string;
  SuccessMessage: boolean;
  @ViewChild('termandcondition') termandcondition: TemplateRef<any>;
  @ViewChild('uniconnecttermandcondition') uniconnecttermandcondition: TemplateRef<any>;
  // Passportback: File | null = null;
  // PassportFront: File | null = null;
  // VisaFileUpload: File | null = null;

  public ProvinceList: any = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ];

  constructor(
    public ApplicationServiceService: ApplicationServiceService,
    public route: ActivatedRoute,
    public Router: Router,
    private modalService: NgbModal,
    private _fileUploadServiceService: FileUploadServiceService
  ) {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
  }

  ngOnInit(): void {
    this.Startdate = new Date();
    this.SIMRegistrationSecModel = new SIMRegistrationAddress();
    //this.SIMRegistrationSecModel.simNumber = '89302370333008091614';
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.SIMRegistrationSecModel.registrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.arrivalStatus = 'Confirmed';
    this.GetCity();
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
  }

  ValidatetheSIMCard(SIMNumber: string) {
    this.DisplayPlanMessage = false;
    this.DisplayMandatoryDiv = false;
    this.DisplayPlanMessageKoodoo = false;
    this.DisplayMandatoryDiv = false;
    if (SIMNumber.length < 15) {
      return;
    }
    this.ApplicationServiceService.SIMCardValidationByNetwork(
      SIMNumber,
      this.NetworkName
    ).subscribe((result: any) => {
      if (result.responseMessage === 'Valid') {
        this.SIMCardStatus = false;
        this.IsValidSIMNumber = false;
        if (result.network === 'Fido') {
          this.DisplayPlanMessage = true;
          this.DisplayMandatoryDiv = true;
        } else if (result.network === 'Koodoo') {
          this.DisplayPlanMessageKoodoo = true;
          this.DisplayMandatoryDiv = true;
        } else {
          this.DisplayPlanMessage = true;
          this.DisplayMandatoryDiv = true;
        }
      } else if (result.responseMessage === 'InValid') {
        if (
          this.SIMRegistrationResponseModel.SIMCardNumberICCID !== undefined ||
          this.SIMRegistrationResponseModel.SIMCardNumberICCID !== '' ||
          this.SIMRegistrationResponseModel.SIMCardNumberICCID != null
        ) {
          if (
            this.SIMRegistrationResponseModel.SIMCardNumberICCID === SIMNumber
          ) {
            this.SIMCardStatus = false;
            this.IsValidSIMNumber = false;

            this.DisplayPlanMessageKoodoo = true;
            this.DisplayMandatoryDiv = true;
          } else {
            this.SIMCardStatus = true;
            this.IsValidSIMNumber = true;
          }
        } else {
          this.SIMCardStatus = true;
          this.IsValidSIMNumber = true;
        }
      }
    });
  }

  OnSubmit(SIMRegistrationSecModel: SIMRegistrationAddress) {
    this.IsSubmitted = true;
    if (
      SIMRegistrationSecModel.arrivalDate === undefined ||
      SIMRegistrationSecModel.arrivalDate == null
    ) {
      alert('Please provide the travel date.!');
      return;
    }

    if (
      SIMRegistrationSecModel.emailID === undefined ||
      SIMRegistrationSecModel.emailID == null ||
      SIMRegistrationSecModel.emailID === ''
    ) {
      alert('Please provide the email id.!');
      return;
    }

    // Check the file validation

    if (
      SIMRegistrationSecModel.passportfilename === null ||
      SIMRegistrationSecModel.passportfilename === undefined ||
      SIMRegistrationSecModel.passportfilename === ''
    ) {
      alert('Please upload the passport front page.!');
      return;
    }

    if (
      SIMRegistrationSecModel.passportbackfilename === null ||
      SIMRegistrationSecModel.passportbackfilename === undefined ||
      SIMRegistrationSecModel.passportbackfilename === ''
    ) {
      alert('Please upload the passport address page.!');
      return;
    }

    if (
      SIMRegistrationSecModel.visafilename === null ||
      SIMRegistrationSecModel.visafilename === undefined ||
      SIMRegistrationSecModel.visafilename === ''
    ) {
      alert('Please upload the visa scan document.!');
      return;
    }

    if (
      SIMRegistrationSecModel.isTermandCondition === null ||
      SIMRegistrationSecModel.isTermandCondition === undefined
    ) {
      alert('Please accept the Fido term and condition');
      return;
    }

    // END

    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.ApplicationServiceService.AddSIMRegistrationaddress(
      this.SIMRegistrationSecModel,
      this.RegistrationID
    ).subscribe((result: any) => {
      this.SIMRegistrationSecModel = result;
      this.Router.navigate(['/Thankyou/' + this.RegistrationID]);
    });
  }

  OnBackButton() {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.Router.navigate(['/SIMCardRegistration/' + this.RegistrationID]);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  }

  NewSaleEntry() {
    this.route.params.subscribe(
      (params) => (this.RegistrationID = params['RegistrationID'])
    );
    this.Router.navigate(['/SIMCardRegistration']);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  }

  BindDataTotheModelWhenPreviousClick(RegistrationID: string) {
    this.SIMRegistrationSecModel = new SIMRegistrationAddress();
    if (RegistrationID != null && RegistrationID !== '') {
      this.ApplicationServiceService.GetSIMRegistrationDetail(
        RegistrationID
      ).subscribe((result: any) => {
        this.SIMRegistrationResponseModel = result;
        this.SIMRegistrationSecModel = result;
        this.SIMRegistrationSecModel.simNumber = result.simNumber;
        if (
          this.SIMRegistrationSecModel.simNumber !== undefined &&
          this.SIMRegistrationSecModel.simNumber !== null &&
          this.SIMRegistrationSecModel.simNumber !== ''
        ) {
          this.ValidatetheSIMCard(this.SIMRegistrationSecModel.simNumber);
        }
        if (
          this.SIMRegistrationSecModel.arrivalStatus === undefined ||
          this.SIMRegistrationSecModel.arrivalStatus === null ||
          this.SIMRegistrationSecModel.simNumber === ''
        ) {
          this.SIMRegistrationSecModel.arrivalStatus = 'Confirmed';
        }
      });
    } else {
      this.SIMRegistrationSecModel.arrivalStatus = 'Confirmed';
    }
  }
  handleFileInputPassportFront(
    files: FileList,
    Type: any,
    SIMRegistrationSecModel: SIMRegistrationAddress,
    f: NgForm
  ) {
    this.FileUploadModel = new FileUploadModel();
    this.FileUploadModel.fileToUpload = files.item(0);
    const fileSizeinMB = this.FileUploadModel.fileToUpload.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100;
    if (Type === 'Visa') {
      if (size >= 1.5) {
        this.ActionMessageVisa =
          'Maximum file size is allowed to upload only 1.5 MB';
        f.controls['visafilename'].setValidators(Validators.required);
        SIMRegistrationSecModel.visafilename = null;
        return;
      }
    } else if (Type === 'PassportFront') {
      if (size >= 1.5) {
        f.controls['passportfilename'].setValidators(Validators.required);
        this.ActionMessageFront =
          'Maximum file size is allowed to upload only 1.5 MB';
        SIMRegistrationSecModel.passportfilename = null;
        return;
      }
    } else if (Type === 'PassportBack') {
      if (size >= 1.5) {
        f.controls['passportbackfilename'].setValidators(Validators.required);
        this.ActionMessageBack =
          'Maximum file size is allowed to upload only 1.5 MB';
        SIMRegistrationSecModel.passportbackfilename = null;
        return;
      }
    }

    this.FileUploadModel.SIMRegistrationID = this.RegistrationID;
    this.FileUploadModel.id = '0';
    this.FileUploadModel.uploadtype = Type;
    this.FileUploadModel.userID = '3';
    this._fileUploadServiceService
      .PostFile(this.FileUploadModel)
      .subscribe((result: any) => {
        this.SuccessMessage = true;
        if (Type === 'Visa') {
          this.ActionMessageVisa = 'File has been uploaded.';
        } else if (Type === 'PassportFront') {
          this.ActionMessageFront = 'File has been uploaded.';
        } else if (Type === 'PassportBack') {
          this.ActionMessageBack = 'File has been uploaded.';
        }
        setTimeout(
          function () {
            this.SuccessMessage = false;
          }.bind(this),
          5000
        );
      });
  }

  closeResult = '';
  open(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
    };

    this.modalService.open(content, ngbModalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openTermCondition() {
    this.open(this.termandcondition);
  }


  openUniconnectTermCondition() {
    this.open(this.uniconnecttermandcondition);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  public City: City[];
  GetCity() {
    this.ApplicationServiceService.CityList().subscribe((result: City[]) => {
      this.City = result;
      let foo_object: any; // Item to remove
      foo_object = this.City.filter((obj) => obj.name === 'All City');
      let index = this.City.findIndex((d) => d.id === foo_object.id); //find index in your array
      this.City.splice(index, 1); //remove element from array
    });
  }
}
