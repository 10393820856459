import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { FileUploadModel } from '../Models/FileUploadModel';

@Injectable({
  providedIn: 'root',
})
export class FileUploadServiceService {
  public BaseUrl: string = 'https://www.canadasimregister.com/APIWEBSERVICE/';
  //public BaseUrl: string = "https://www.canadasimregister.com/APISERVICEQA/";
  // public BaseUrl: string = "http://localhost:61908/";
  constructor(private http: HttpClient) {}

  PostFile(FileUploadModel: FileUploadModel): Observable<any> {
    const endpoint = this.BaseUrl + 'api/upload/file';
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    const formData: FormData = new FormData();
    formData.append(
      'File',
      FileUploadModel.fileToUpload,
      FileUploadModel.fileToUpload.name
    );
    formData.append('SIMRegistrationID', FileUploadModel.id);
    formData.append('RegistrationGUID', FileUploadModel.SIMRegistrationID);
    formData.append('uploadtype', FileUploadModel.uploadtype);
    formData.append('UserID', '3');
    return this.http.post<any>(endpoint, formData);
  }
}
