import { SIMRegistration } from './simregistration';

export class SIMRegistrationSec {
  SImNumber: string;
  PlanID: number;
  SIMRegistrationID: string;
  id: number;
  DateofTravel?: Date;
  DateofTravelTentative: string;
  EmailID: string;
  SourceName: string = 'uniconnectvirginplus';
}

export class SIMRegistrationAddress extends SIMRegistration {
  simNumber: string ;
  planID: number = 0;
  arrivalDate?: Date;
  arrivalStatus: string;
  canadianAddress: string;
  postalCode: string;
  province: string;
  dateofBirth?: Date;
  universityName: string;
  passportfilename: string;
  passportbackfilename: string;
  visafilename: string;
  isTermandCondition: boolean = false;
  agrrementwithYou: boolean = false;
  verginplusconsent: boolean = false;
}
